<template>
  <navigation-mobile-pos
    title="Areas"
    tableHeader="true"
  />

  <div
    class="flex flex-col stretcher-content"
    v-cloak
  >
    <loading-indicator
      v-if="!initialized"
      class="mt-64"
    />
    <div
      v-if="initialized"
      class="flex-grow px-4"
    >
      <div class="mt-4 flex flex-wrap flex-col items-start space-y-2.5">
        <LocationCollapsible
          v-for="(location, index) in indexedLocations"
          :key="index"
          :location="location"
          :is-open="index === 0"
          @select="areaSelect($event)"
        />

        <router-link
          :to="{
            name: 'mobilepos.tables',
            params: { area_id: 'virtualtables' },
          }"
          class="p-4 flex list-item flex-1 basis-full"
        >
          <div class="text font-bold">
            {{ $t("translations.views.mobilepos.pages.areas.virtual-tables") }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import LocationCollapsible from "@/components/mobilepos/LocationCollapsible.vue"
// mixins
import TerminalLayoutMixin from "@/mixins/terminal-layout.mixin"
import LoadingIndicator from "../../../components/LoadingIndicator"
// components
import NavigationMobilePos from "../../../components/NavigationMobilePos"

export default {
  name: "mobilepos.areas",

  mixins: [TerminalLayoutMixin],
  components: {
    LocationCollapsible,
    NavigationMobilePos,
    LoadingIndicator,
  },

  data() {
    return {
      initialized: false,
      locations: [],
      areas: [],
    }
  },

  async mounted() {
    this.areas = []

    await Promise.all([this.areasGet(), this.getLocations()])
    this.initialized = true
  },

  methods: {
    reload() {
      location.reload()
    },

    async getLocations() {
      axios
        .get("/tables/locations")
        .then((response) => {
          this.locations = response?.data?.data ?? []
        })
        .catch(() => {
          this.$notify(
            {
              group: "notifications",
              type: "error",
              title: "Fout opgetreden",
              text: "Oeps er ging iets fout..",
            },
            2000
          )
        })
    },

    async areasGet() {
      this.areas = []
      axios
        .get("/areas?cross_selection=1")
        .then((response) => {
          this.areas = response.data.data

          // filter tables for clerk
          if (
            this.clerk &&
            this.clerk.area_ids &&
            this.clerk.area_ids.length > 0
          ) {
            this.areas = this.areas.filter((area) => {
              return this.clerk.area_ids.indexOf(area.id) >= 0
            })
          }
        })
        .catch(() => {
          this.$notify(
            {
              group: "notifications",
              type: "error",
              title: "Fout opgetreden",
              text: "Oeps er ging iets fout..",
            },
            2000
          )
        })
    },

    areaSelect(area) {
      this.$store.dispatch("transaction/setTable", area)
      this.$router.push({ name: "pos" })
    },
  },
  computed: {
    clerk() {
      return this.$store.getters["transaction/clerk"]
    },

    terminal() {
      return this.$store.getters["terminal/terminal"]
    },

    indexedLocations() {
      if (!this.initialized) return {}

      for (const location of this.locations) {
        location.areas = this.areas.filter(
          (area) => String(area?.attributes.location_id) === String(location.id)
        )
      }

      return this.locations.filter((location) => location.areas.length > 0)
    },
  },
}
</script>
